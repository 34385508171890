<template>
        <v-row class="mt-4">
			<v-col class="pl-5 pr-5">
				<span class="overline">{{ mwtr.UmsetzungProgress }}</span>
				<v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-progress-linear
                            v-on="on"
                            height="13"
                            color="green"
                            :value="value"
                        > </v-progress-linear>
                    </template>
                    {{value}}% 
                </v-tooltip>
			</v-col>
		</v-row>
</template>

<script>
export default {
    name: "UmsetzungBar", 
    props: {
        value: {}
    }
}
</script>