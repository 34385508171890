<template>
	<v-btn-toggle v-model="type" class="mr-4 ml-3">
		<v-btn icon v-for="type in chartTypes" :key="type.name" :value="type.name">
			<v-icon>{{type.icon}}</v-icon>
		</v-btn>
	</v-btn-toggle>
</template>

<style lang="less">
	.chartWrapper {
		width: 100%;
		position: relative;
	}
</style>

<script type="text/javascript">
	// Selects the chart type - required toggle button
	export default {
		name: "ChartType", 
		data: () => {
			return {
				type: "BarChart"
			}
		},
		computed: {
			chartTypes(){
				return [
					{
						name: "BarChart", 
						icon: "mdi-chart-bar", 
						title: this.mwtr.Charts.BarChart
					},
					{
						name: "LineChart", 
						icon: "mdi-chart-line", 
						title: this.mwtr.Charts.LineChart
					},
					{
						name: "AreaChart", 
						icon: "mdi-chart-areaspline-variant", 
						title: this.mwtr.Charts.AreaChart
					},
					{
						name: "HBarChart", 
						icon: "mdi-chart-gantt", 
						title: this.mwtr.Charts.HBarChart
					},
				]
			},
		},
		watch: {
			type(v) {
				this.$emit("change", v );
			}, 
		}

	}
// </script>"