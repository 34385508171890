<template>
	<vue-excel-xlsx
		:columns="headers"
		:data="rows"
		:filename="name">
		<v-btn v-if="icon" icon><v-icon>mdi-download</v-icon></v-btn>
		<v-btn color="primary" v-else>{{$t("buttons.download")}}</v-btn>
	</vue-excel-xlsx>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">    
	import VueExcelXlsx from "vue-excel-xlsx";
    import Vue from "vue";
    Vue.use(VueExcelXlsx);

	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "Export", 
		props: {
			rows: {},
			columns: {},
			icon: {type: Boolean, default: false },
			name: {default: "file"}
		},
		data: () => {
			return {
				
				}
		},
		// components: {
			
			// },
		computed: {
			headers(){
				var columns = this.columns;
				return Object.keys(columns).map( c => {
					return {field: columns[c], label: c}
				});
			}
			// ...Vuex.mapState({})
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"