var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-spacer'),_c('Export',{attrs:{"icon":"","columns":_vm.tableColumns,"rows":_vm.items,"name":"handlungsbedarf"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"table","headers":_vm.headers,"items":_vm.items,"expanded":_vm.expanded,"item-key":"id","show-expand":_vm.showExpand},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sort-by":_vm.sorted},scopedSlots:_vm._u([{key:"header.relevanz",fn:function(){return [_c('v-menu',{attrs:{"open-on-hover":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('v-card',{staticClass:"pa-2 pt-4"},[_c('p',[_vm._v("Absolut nicht relevant")]),_c('p',[_vm._v("Eher nicht relevant")]),_c('p',[_vm._v("Eher relevant")]),_c('p',[_vm._v("Absolut relevant")])])],1),_vm._v(" Relevanz ")]},proxy:true},{key:"header.umsetzung",fn:function(){return [_c('v-menu',{attrs:{"open-on-hover":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('v-card',{staticClass:"pa-2 pt-4"},[_c('p',[_vm._v("0%: Umsetzung nicht absehbar")]),_c('p',[_vm._v("25%: Umsetzung definiert und beschrieben")]),_c('p',[_vm._v("50%: Umsetzung entschieden und geplant")]),_c('p',[_vm._v("75%: Umsetzung in Arbeit")]),_c('p',[_vm._v("100%: Umsetzung abgeschlossen")])])],1),_vm._v(" Umsetzung ")]},proxy:true},{key:"header.dringlichkeit",fn:function(){return [_c('v-menu',{attrs:{"open-on-hover":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('v-card',{staticClass:"pa-2 pt-4"},[_c('p',[_vm._v("1 - 5 Jahre")])])],1),_vm._v(" Dringlichkeit ")]},proxy:true},{key:"header.handlungsbedarf",fn:function(){return [_c('v-menu',{attrs:{"open-on-hover":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('v-card',{staticClass:"pa-2 pt-4"},[_c('p',[_vm._v("0: kein handlungsbedarf")]),_c('p',[_vm._v("5: maximaler Handlungsbedarf")])])],1),_vm._v(" Handlungsbedarf ")]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{attrs:{"width":"100%"}},_vm._l((_vm.singleItems.filter(
								function (a) { return a.goal == item.goal; }
							)),function(row){return _c('tr',{key:row.response},[_c('td',{staticClass:"pa-1",attrs:{"width":_vm.widths[0]}}),_c('td',{staticClass:"pa-1",attrs:{"width":_vm.widths[1]}},[_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(row.goalGroup)+", "+_vm._s(row.department))])]),_c('td',{staticClass:"pa-1",attrs:{"width":_vm.widths[2]}},[_vm._v(" "+_vm._s(row.relevanz)+" ")]),_c('td',{staticClass:"pa-1",attrs:{"width":_vm.widths[3]}},[_vm._v(" "+_vm._s(row.umsetzung)+"% ")]),_c('td',{staticClass:"pa-1",attrs:{"width":_vm.widths[4]}},[_vm._v(" "+_vm._s(row.dringlichkeit)+"J. ")]),_c('td',{staticClass:"pa-1",attrs:{"width":_vm.widths[4]}},[_c('v-avatar',{attrs:{"color":"accent","size":"42"}},[_c('span',{staticClass:"small"},[_vm._v(" "+_vm._s(row.handlungsbedarf)+" ")])])],1)])}),0)])]}},{key:"item.goal",fn:function(ref){
							var item = ref.item;
return [_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(_vm._s(item.goalName))]),(_vm.sortBy != 'goal')?_c('span',[_c('br'),_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.goalGroup)+", "+_vm._s(item.department))])]):_vm._e()]}},{key:"item.handlungsbedarf",fn:function(ref){
							var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"accent","size":"42"}},[_c('span',{staticClass:"small"},[_vm._v(" "+_vm._s(item.handlungsbedarf)+" ")])])]}},{key:"item.dringlichkeit",fn:function(ref){
							var item = ref.item;
return [(!_vm.showExpand && item.dringlichkeit)?_c('span',[_vm._v(_vm._s(item.dringlichkeit)+" J. ")]):_vm._e()]}},{key:"item.umsetzung",fn:function(ref){
							var item = ref.item;
return [(!_vm.showExpand)?_c('span',[_vm._v(" "+_vm._s(item.umsetzung)+" % ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }