<template>
    <v-item v-slot:default="{ active, toggle }" :value="id">
        <v-chip class="mb-2 pa-3" :color="active ? color : 'white'" :dark="active"  @click="toggle"  :class="{active: active}">
            <SelectColor :color="color" @change="(v) => $emit('setColor', v)"></SelectColor>
            <span class="pr-5 label">{{label}}</span>
        </v-chip>
    </v-item>
</template>

<style lang="less" scoped>
    .v-chip {
        span.label {
            text-decoration: line-through;
        }
        &.active {
            span.label {
                text-decoration: none;
            }
        }
    }
</style>

<script type="text/javascript">
	// import Vuex from "vuex"; 
	// import - from "@/components/"
	import SelectColor from "@/components/survey/results/SelectColor";


    export default {
		name: "SeriesSelectionPanel", 
		props: {
            color: {},
            id: {},
            label: {}
		},
		data: () => {
			return {
            }
		},
		computed: {

		},
        components: {
            SelectColor
        },
		methods: {
		}
		// created(){
			
		// }

	}
// </script>"