import { render, staticRenderFns } from "./Analysis.vue?vue&type=template&id=20dfc42e&"
import script from "./Analysis.vue?vue&type=script&lang=js&"
export * from "./Analysis.vue?vue&type=script&lang=js&"
import style0 from "./Analysis.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VBtnToggle,VCol,VDataTable,VExpansionPanels,VIcon,VItemGroup,VRow,VSheet,VSpacer,VToolbar})
