<template>
	<v-sheet v-if="map">
		<umsetzung-bar v-model="umsetzung"></umsetzung-bar>
		<v-row>
			<v-toolbar color="transparent" flat>
				<v-btn-toggle v-model="seriesAggregation" class="mr-3" mandatory>
					<v-btn value="average">{{ mwtr.Charts.Average }}</v-btn>
					<v-btn value="departments">Bereiche</v-btn>
					<v-btn value="parts">{{ mwtr.Charts.GoalGroups }}</v-btn>
				</v-btn-toggle>
				<v-btn-toggle v-model="visualisationType" mandatory>
					<v-btn icon value="chart"><v-icon>mdi-chart-bar</v-icon></v-btn>
					<v-btn icon value="table"><v-icon>mdi-table</v-icon></v-btn>
				</v-btn-toggle>
				<ChartTypes
					v-if="visualisationType == 'chart'"
					@change="(e) => (chartType = e)"
				></ChartTypes>
				<v-spacer></v-spacer>
				<SaveImage
					v-if="visualisationType == 'chart'"
					id="chart"
				></SaveImage>
				<Export
					v-else
					icon
					:columns="tableColumns"
					:rows="tableRows"
					name="vis"
				></Export>
			</v-toolbar>
		</v-row>
		<v-row>
			<v-col cols="12" sm="4" class="mt-4">
				<v-item-group v-model="selectedSeries" multiple>
					<SeriesSelectionPanel
						v-for="s in series"
						:key="s.id"
						v-bind="s"
						:color="seriesColors[s.id]"
						@setColor="(v) => setColor(s.id, v)"
					></SeriesSelectionPanel>
				</v-item-group>
				<v-item-group v-model="selectedParts" multiple>
					<v-expansion-panels flat>
						<DataSelectionPanel
							v-for="id in departmentOrder"
							:key="id"
							:id="id"
							:selected="selectedParts"
							@select="toggleDepartment"
						></DataSelectionPanel>
					</v-expansion-panels>
				</v-item-group>
				<v-btn text @click="selectAll" small>
					{{ mwtr.SelectAll	}}
					</v-btn>
				<v-btn
					text
					@click="selectedParts = []"
					small
					v-if="selectedParts.length > 0"
					>{{ mwtr.DeselectAll }}</v-btn
				>
			</v-col>
			<v-col cols="12" sm="8">
				<Chart
					:loading="loading"
					id="chart"
					v-if="visualisationType == 'chart'"
					:type="chartType"
					v-bind="output"
					:options="chartType == 'HBarChart' ? hOptions : vOptions"
				></Chart>
				<v-data-table
					v-else
					:items="tableRows"
					:headers="tableColumns"
				></v-data-table>
			</v-col>
		</v-row>
		<v-row v-if="!loading">
			<Handlungsbedarf :data="myGoalScores"></Handlungsbedarf>
		</v-row>
	</v-sheet>
</template>

<style lang="less">
@import "./../assets/css/results/charts.less";
</style>

<script type="text/javascript">
import Vue from "vue";
import Vuex from "vuex";
import ChartTypes from "@/components/survey/results/ChartTypes";
import Chart from "@/components/survey/results/Chart";
import UmsetzungBar from "@/components/survey/results/UmsetzungBar";
import SeriesSelectionPanel from "@/components/survey/results/SeriesSelectionPanel";
import DataSelectionPanel from "@/components/survey/results/DataSelectionPanel";
import SaveImage from "@/components/elements/SaveImage";
import Export from "@/components/elements/Export";
import Handlungsbedarf from "@/components/survey/results/Handlungsbedarf";

export default {
	name: "Analysis",
	props: {},
	data: () => {
		return {
			loading: false,
			seriesAggregation: "average",
			visualisationType: "chart",
			chartType: "BarChart",
			selectedParts: [],
			selectedSeries: ["s1"],
			vOptions: {
				scales: {
					yAxes: [
						{
							display: true,
							scaleLabel: {
								display: true,
								labelString: "Handlungsbedarf",
							},

							ticks: {
								max: 5,
								beginAtZero: true, // minimum value will be 0.
							},
						},
					],
				},
			},
			hOptions: {
				scales: {
					xAxes: [
						{
							display: true,
							scaleLabel: {
								display: true,
								labelString: "Handlungsbedarf",
							},

							ticks: {
								max: 5,
								beginAtZero: true, // minimum value will be 0.
							},
						},
					],
				},
			},
			seriesColors: {
				s1: "",
				s2: "",
			},
		};
	},
	components: {
		Chart,
		ChartTypes,
		DataSelectionPanel,
		SeriesSelectionPanel,
		Export,
		Handlungsbedarf,
		SaveImage,
		UmsetzungBar,
	},
	computed: {
		...Vuex.mapState({
			assessment: (state) => state.assessment,
			departments: (state) => state.departments,
			goals: (state) => state.goals,
			goalGroups: (state) => state.goalGroups,
		}),
		series() {
			return [
				{
					label: this.mwtr.Charts.YourScore,
					id: "s1",
					source: "own_data",
				},
			];
		},
		questions() {
			return this.$store.getters.questionArray;
		},
		umsetzung() {
			var self = this;
			var scores = self.dataPoints.map((a) =>
				self.mwsurveyutilities.umsetzung(a)
			);
			return this.mwsurveyutilities.averageScore(scores);
		},

		questionLength() {
			return this.questions.length;
		},
		departmentOrder() {
			return this.$store.getters.orderOfDepartments
		},
		map() {
			return this.$store.getters.igMapped;
		},
		labelMap() {
			const self = this;
			var labels = {
				average: this.mwtr.Charts.Average,
			};
			var map = this.map;
			Object.keys(map).forEach((m) => {
				labels[m] = map[m].name;
			});
			Object.keys(self.goalGroups).forEach( g => 
				labels[g] = self.goalGroups[g].name
			)
			return labels;
		},
		sortOrder() {
			const self = this;
			let order = [];
			if (self.seriesAggregation == "departments") {
				order = self.departmentOrder;
			}
			if (self.seriesAggregation == "parts") {
				order = Object.keys(self.map).flatMap((m) => {
					return self.map[m].partOrder.flatMap((p) => {
						return self.map[m].parts[p].goalGroup;
					});
				});
			}
			return order;
		},

		haveConfig() {
			return true;
		},
		canAccessAssessment() {
			return this.$store.getters.canAccessAssessment;
		},
		goalMap() {
			return this.$store.getters.completionRatesFlat;
		},
		mappedGoalGroups(){
			return this.$store.getters.partGoalGroups;
		},
		dataPoints() {
			var self = this;
			var goals = self.goalMap.filter((g) =>
				self.selectedParts.includes(g.part)
			);
			goals = goals.filter((a) => {
				return a.data && Object.keys(a.data).length;
			});
			return goals;
		},
		myGoalScores() {
			var self = this;
			var scores = Object.fromEntries(
				self.dataPoints.map((goal) => {
					return [
						goal.uniqueId,
						self.mwsurveyutilities.calculateScore(
							self.questions,
							goal.data
						),
					];
				})
			);
			return scores;
		},
		tableColumns() {
			var columns = [{ text: "Item", value: "item" }];
			this.selectedSeries.forEach((s) => {
				var series = this.series.find((a) => a.id == s);
				columns.push({ text: series.label, value: s });
			});
			return columns;
		},
		tableRows() {
			var self = this;
			var data = [];
			self.output.labels.forEach((label, i) => {
				var item = { item: label };
				self.output.data.forEach((d) => {
					item[d.id] = d.data[i] || "-";
				});
				data.push(item);
			});
			return data;
		},
		output() {
			const self = this;

			var datasets = {
				data: [],
				labels: [],
			};

			if (!self.selectedParts.length || !self.selectedSeries.length) {
				return datasets;
			}

			self.selectedSeries.forEach((seriesId) => {
				var series = self.series.find((a) => a.id == seriesId);
				var input;
				input = self.myGoalScores;

				var output = self.calculateSeriesData(input);
				datasets.labels = Object.keys(output);

				datasets.data.push({
					backgroundColor: self.seriesColors[series.id] || "grey",
					label: series.label || "value",
					id: series.id,
					data: Object.values(output) || [],
				});
			});
			datasets.labels = self.sortLabels(datasets.labels);
			datasets.labels = datasets.labels.map((l) => self.labelMap[l] || l);

			return datasets;
		},
	},
	methods: {
		setColor(id, color) {
			Vue.set(this.seriesColors, id, color);
		},
		sortLabels(arr) {
			const self = this;
			let order = arr.sort((a, b) => {
				var x = self.sortOrder.indexOf(a);
				var y = self.sortOrder.indexOf(b);
				return x < y ? -1 : x > y ? 1 : 0;
			});
			return order;
		},
		selectAll() {
			const self = this;
			Object.keys(self.departments).forEach((d) => {
				self.toggleDepartment(d, true);
			});
		},
		toggleDepartment(department, show) {
			var self = this;
			if( !self.map[department]){
				return;
			}
			var parts = self.map[department].partOrder;
			if (show) {
				parts.forEach((part) => {
					if (!self.selectedParts.includes(part)) {
						self.selectedParts.push(part);
					}
				});
			} else {
				self.selectedParts = self.selectedParts.filter(
					(part) => !parts.includes(part)
				);
			}
		},
		calculateSeriesData(input) {
			const self = this;
			var scores = {};

			if (self.seriesAggregation == "average") {
				scores.average = Object.values(input);
			} else {
				self.dataPoints.forEach((dp) => {
					var key;
					if (self.seriesAggregation == "departments") {
						key = dp.department;
					}
					if (self.seriesAggregation == "parts") {
						let goalGroup = self.mappedGoalGroups[dp.part]
						key = goalGroup;
					}
					if (!scores[key]) {
						scores[key] = [];
					}
					scores[key].push(input[dp.uniqueId]);
				});
			}

			var average = {};
			for (var key in scores) {
				average[key] = 	self.mwsurveyutilities.averageScore(scores[key])
			}

			return average;
		},
	},
	watch: {
		questionLength: {
			immediate: true,
			handler() {
				// fetch answer options for these questions once the question have loaded
				var questions = this.questions;
				questions.forEach((q) => {
					this.$store.dispatch("questions/fetchAnswerOptions", q.id);
				});
			},
		},
	},
	created() {
		this.seriesColors.s1 = this.$vuetify.theme.themes.light.primary;
		// this.seriesColors.s2 = this.$vuetify.theme.themes.light.accent;
	},
};

//
</script>
"
