    
<template>
    <v-menu>
        <template v-slot:activator="{on}">
            <v-btn small class="ma-2" v-on="on" icon><v-icon small>mdi-format-color-fill</v-icon></v-btn>
        </template>
        <v-color-picker
           @input="v => $emit('change', v.hex || v)"
           v-model="value"
        ></v-color-picker>
    </v-menu>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "SelectColour", 
		props: {
			color: {}
		},
		data: () => {
			return {
                value: false,
			}
		},
        created(){
			this.value = this.color;
		},
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"