<template>
	<v-expansion-panel class="no-padding">
		<v-list-item>
			<v-checkbox v-model="checked" :indeterminate="some"></v-checkbox>
			<v-expansion-panel-header>
				{{ section.name }}
				<v-badge
					color="green"
					:content="count || '0'"
					class="mr-7 mt-3"
				></v-badge>
			</v-expansion-panel-header>
		</v-list-item>
		<v-expansion-panel-content>
			<v-item
				v-for="p in section.partOrder"
				:key="p"
				v-slot:default="{ active, toggle }"
				:value="p"
			>
				<div class="toggleChip" :class="{ active: active }" @click="toggle">
					{{ section.parts[p].name }}
				</div>
			</v-item>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<style lang="less">
.v-expansion-panel.no-padding {
	.v-expansion-panel-header,
	.v-expansion-panel-content__wrap {
		padding-left: 0px !important;
	}
}
</style>

<script type="text/javascript">
// import Vuex from "vuex";
// import - from "@/components/"
export default {
	name: "DataSelectionPanel",
	props: {
		id: {},
		active: {},
		selected: {},
	},
	data: () => {
		return {
			checked: false,
		};
	},
	computed: {
		// ...Vuex.mapState({})
		section() {
			return this.$store.getters.igMapped[this.id];
		},
		count() {
			var self = this;
			var included = self.section.partOrder.filter((a) =>
				self.selected.includes(a)
			);
			return included.length || 0;
		},
		all() {
			return this.count == this.section.partOrder.length;
		},

		some() {
			return this.count > 0 && this.count < this.section.partOrder.length;
		},
	},
	watch: {
		checked(value) {
			this.toggleAll(value);
		},
		all(value) {
			if (!value == this.checked) {
				this.checked = value;
			}
		},
	},

	methods: {
		toggleAll(value) {
			this.$emit("select", this.id, value);
		},
	},
	// created(){

	// }
};
//
</script>
"
