<template>
  <v-sheet class="pt-4">
    <v-row>
      <div class="chartWrapper">
        <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
        <v-component
          v-if="!loading"
          :options="options"
          :height="height"
          :is="type"
          :chart-data="dataCollection"
        ></v-component>
      </div>
    </v-row>
  </v-sheet>
</template>

<style lang="less">
.chartWrapper {
  width: 100%;
  position: relative;
}
</style>

<script type="text/javascript">
	// renders the chart 
	export default {
	name: "Chart",
	props: {
		type: { type: String, default: "BarChart" }, //  options:  ["BarChart", "HBarChart", "LineChart", "AreaChart"]
		loading: { type: Boolean, default: false },
		// Chart.js config options https://www.chartjs.org/docs/latest/
		data: { type: Array },
		labels: { type: Array },
		options: { type: Object },
	},
	data: () => {
		return {
		height: 200,
		};
	},
	components: {
		BarChart: () => import("@/assets/charts/BarChart.js"),
		HBarChart: () => import("@/assets/charts/HBarChart.js"),
		LineChart: () => import("@/assets/charts/LineChart.js"),
		AreaChart: () => import("@/assets/charts/AreaChart.js"),
	},
	computed: {
		dataCollection() {
		var data = this.data;
		return {
			labels: this.labels,
			datasets: data,
		};
		},
	},
	};
	//
</script>
